<template>
  <div class="success-box">
    <div class="success-icon">
      <img src="@@/assets/img/success/success.png" class="s-icon">
    </div>
    <p class="success-title">提交成功</p>
    <div class="success-order-list">
      <div class="order-list-header">
        <div class="header-row">订单类型</div>
        <div class="header-row">订单号</div>
        <div class="header-row">预估金额</div>
      </div>
      
      <!-- PCB订单 -->
      <div v-if="isPcb" class="order-list-content">
        <div class="content-row">
          PCB订单
        </div>
        <div class="content-row">
          <BaseLink @click="handleOrder('pcb')">
            {{ ordersData.id }}
          </BaseLink>
        </div>
        <div class="content-row row-price">
          ￥{{ ordersData.webpay }}
        </div>
      </div>

      <!-- PCBA订单 -->
      <template v-else>
        <!-- PCB订单 -->
        <div v-if="ordersData.customPcbBan === 1" class="order-list-content">
          <div class="content-row">
            PCB订单
          </div>
          <div class="content-row">
            <BaseLink @click="handleOrder('pcb')">
              {{ ordersData.pcbOrderId }}
            </BaseLink>
          </div>
          <div class="content-row row-price">
            ￥{{ ordersData.pcbPrice }}
          </div>
        </div>
        <!-- PCBA订单 -->
        <div class="order-list-content">
          <div class="content-row">
            PCBA订单
          </div>
          <div class="content-row">
            <BaseLink @click="handleOrder('smt')">
              {{ ordersData.demandSn }}
            </BaseLink>
          </div>
          <div class="content-row row-price">
            <!-- SMT报价 -->
            <p><span>￥{{ ordersData.smtPrice }}</span>（SMT）</p>
            <!-- BOM 华秋帮我选型 -->
            <p v-if="ordersData.buyBom === 1 || ordersData.modelSelectionType === 0">待人工报价（BOM）</p>
            <!-- BOM 自助匹配选型 -->
            <p v-if="ordersData.buyBom === 4 && [1, 2].includes(ordersData.modelSelectionType)" class="bom">
              <!-- 部分自助匹配部分华秋代配/询价 -->
              <template v-if="ordersData.modelSelectionType === 2">
                已配器件<span>￥{{ ordersData.bomTotalPrice }}</span><br />
                待询价/代配器件：待人工报价
              </template>
              <!-- 全部自助匹配 -->
              <template v-else>
                <span>￥{{ ordersData.bomTotalPrice }}</span>（BOM）
              </template>
            </p>
          </div>
        </div>
      </template>
    </div>

    <template v-if="isPcb">
      <div class="order-tips">
        我们将尽快审核您的订单，审核后将通知您进行付款
        <BaseTextButton style="margin-left: 16px;" @click="handleShowIntro">审单及生产时效说明</BaseTextButton>
      </div>
      <!-- 公众号二维码 -->
      <template v-if="isWechatBind != 1">
        <div class="success-qrcode"><img src="@@/assets/img/success/success-qrcode.png" alt="" /></div>
        <p class="success-apply-tips">审核完成后，客服人员会主动联系您</p>
      </template>
    </template>
    <div v-else class="order-tips">
      我们将尽快审核您的订单，审核后将通知您进行付款
    </div>
    
    <div class="success-other">
      <a v-if="!isPcbaReturn" href="javascript:void(0)" class="other-continue" @click="handleContinueOrder">继续下单</a>
      <a href="javascript:void(0)" class="other-view" @click="handleViewOrder">查看订单</a>
      <a v-if="isPcbSite" href="javascript:void(0)" class="other-smt" @click="handleGoSmt">去下SMT</a>
    </div>
    <div v-if="service.saleUserName && service.saleTel" class="customer-service">
      <div class="customer-service-img">
        <img src="@@/assets/img/success/kefu.png" alt="">
      </div>
      <div class="customer-service-intro">
        <p class="intro-title">专属客户经理</p>
        <p class="intro-desc">
          <span>{{ service.saleUserName }}</span>
          <span>{{ service.saleTel }}</span>
        </p>
      </div>
    </div>
    <el-dialog 
      width="600px" 
      append-to-body
      title="审单及生产时间说明" 
      class="intro-dialog" 
      :visible.sync="introDialogVisible"
      :close-on-click-modal="false" 
    >
      <div class="intro-main">
        <h4 class="intro-main-time">审单时间</h4>
        <p class="intro-main-p">· 周一至周六：<span>上午9:00-11:50，下午13:30-18:00</span>，法定节假日审单时间另行通告。</p>
        <h4 class="intro-main-time">生产安排时间</h4>
        <p class="intro-main-p">· 周一至周六：<span>上午9:00-下午18:00</span> 前付款当天安排，超过<span>18:00</span>支付则交期顺延一天。</p>
        <p class="intro-main-p">· 周日不安排订单生产，法定节假日生产安排时间另行通知。</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CONFIG from '@@/config'
import { targetSelfWindow } from '@@/utils/triggerParent'
import { getSaleByLine } from '@@/api/center'
import { sensorsTrack } from '@@/utils/sensors'
import { isPcbSite, isSmtSite } from '@@/utils/is'

export default {
  props: {
    // 保留给后续 判断环境使用
    parentSite: {
      type: String,
      default: 'mycenter',
    },
  },
  data() {
    return {
      introDialogVisible: false,
      isWechatBind: 0, // 是否关注已 华秋电路 公众号 1-已关注 0-未关注
      type: '',
      ordersData: {},
      service: {
        saleUserName: '', // 专属客服名称
        saleTel: '' // 专属客服手机
      },
      isPcbSite,
      isPcbaReturn: false, // 是否是PCBA返单订单
    }
  },
  computed: {
    // PCB/FPC订单
    isPcb() {
      return ['PCB', 'FPC'].includes(this.type)
    },
  },
  mounted() {
    const id = this.$route.query.id
    const ordersData = sessionStorage.getItem(`orders_${id}`)
    if (ordersData) {
      const { type, data, isWechatBind, isPcbaReturn } = JSON.parse(ordersData)
      this.type = type
      this.ordersData = data
      this.isWechatBind = isWechatBind
      this.isPcbaReturn = isPcbaReturn === 1
    }

    this.getServiceList()

    // 神策数据埋点
    sensorsTrack('PCB1_quotation_submit_success', { entrance: '新版订单提交成功页面浏览' })

    this.setFooterStyle(true)
  },
  destroyed() {
    this.setFooterStyle(false)
  },
  methods: {
    // 添加设置底部样式的class
    setFooterStyle(isAdd) {
      const layoutDom = document.querySelector('#app > .layout')
      if (layoutDom) {
        if (isAdd) {
          layoutDom.classList.add('success-layout')
        } else {
          // 离开页面时，需要删除class以免影响到别的页面样式
          layoutDom.classList.remove('success-layout')
        }
      }
    },
    // 查看订单
    handleOrder(type) {
      type = type === 'fpc' ? 'pcb' : type
      const { id, pcbOrderId, demandSn } = this.ordersData
      const pcbId = this.isPcb ? id : pcbOrderId
      if (isPcbSite) {
        location.href = `/mycenter/order/pcb/detail?id=${id}`
      } else if (isSmtSite) {
        const path = {
          pcb: `${CONFIG.HQCHIP_HOST}/mycenter/order/pcb/detail?id=${pcbId}`,
          smt: `${CONFIG.HQCHIP_HOST}/mycenter/order/pcba/list/new/detail?demandSn=${demandSn}`
        }[type]
        location.href =path
      } else {
        const path = {
          pcb: `/order/pcb/detail?id=${pcbId}`,
          smt: `/order/pcba/list/new/detail?demandSn=${demandSn}`,
        }[type]
        targetSelfWindow(path)
      }
    },
    // 继续下单
    handleContinueOrder() {
      if (isPcbSite) {
        const type = this.type.split('_')[0].toLowerCase()
        this.$router.push(type === 'fpc' ? '/quote_new/fpc' : '/quote_new')
      } else if (isSmtSite) {
        this.$router.push('/quote')
      } else {
        const path = this.$route.path.replace('success', 'quote')
        targetSelfWindow(path)  
      }
    },
    // 查看订单
    handleViewOrder() {
      this.handleOrder(this.type.split('_')[0].toLowerCase())
    },
    // 去下SMT
    handleGoSmt() {
      const pcb_sn = this.ordersData.id
      location.href = `${CONFIG.SMT_HOST}/quote?site=pcb&entrance=8&pcb_sn=${pcb_sn}`
    },
    handleShowIntro() {
      this.introDialogVisible = true
    },
    // 获取专属客服
    getServiceList() {
      getSaleByLine({bizLine: this.type.includes('SMT') ? 'PCBA' : "PCB"})
        .then(res => {
          if (!res) return 
          const { suc, body = {} } = res
          if (suc) {
            const { pcbSale, pcbaSale } = body
            const service = this.type.includes('SMT') ? pcbaSale : pcbSale
            this.service = (Array.isArray(service) ? service[0] : service) || {}
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.success-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 39px;
  background: #fff;
  border-radius: 4px;
  .success-icon {
    margin-top: 47px;
    .s-icon {
      width: 100%;
    }
  }
  .success-title {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 400;
    color: #111111;
  }
  .success-order-list {
    width: 472px;
    padding: 4px;
    margin-top: 20px;
    border: 1px dashed #d8d8d8;
    border-radius: 3px;
    .order-list-header,
    .order-list-content {
      display: flex;
      align-items: center;
      line-height: 32px;
    }
    .order-list-header {
      background: #F6F6F6;
      border-radius: 2px;
      color:#666666;
    }
    .order-list-content {
      margin: 0 9px;
      color: #333;
      border-bottom: 1px dashed #d8d8d8;
      &:last-child {
        border-bottom: 0;
      }
    }
    .header-row{
      flex: 1;
      &:first-child {
        flex-grow: 0.6;
        padding-left: 9px;
      }
    }
    .content-row {
      &:first-child {
        flex-grow: 0.6;
      }
      flex: 1;
      a {
        color: #0070CC;
      }
      &.row-price {
        color: #D9001B;
        >p {
          color: $--color-text-disabled;
          >span {
            color: #D9001B;
          }
          &:not(:last-child) {
            border-bottom: 1px dashed #d8d8d8;
          }
          &.bom {
            padding-top: 4px;
            line-height: 20px;
          }
        }
      }
      .row-quote-price {
        color: #aaa;
      }
    }
  }
  .order-tips {
    margin-top: 15px;
    width: 480px;
    height: 40px;
    font-size: 13px;
    color: #666;
    line-height: 40px;
    text-align: center;
    border: 1px dashed #d8d8d8;
    border-radius: 3px;
  }
  .success-qrcode {
    margin-top: 10px;
    img {
      width: 120px;
    }
  }
  .success-apply-tips {
    margin-top: 5px;
    font-size: 13px;
    color: #666;
  }
  .success-other{
    margin-top: 29px;
    
    .other-continue,
    .other-view,
    .other-smt {
      display: inline-block;
      margin: 0 10px;
      width: 105px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 13px;
      border-radius: 17px;
    }
    .other-continue {
      border: 1px solid $--color-warning;
      color: $--color-warning;
    }
    .other-view {
      border: 1px solid $--form-color-base;
      color: $--form-color-base;
    }
    .other-smt {
      color: #fff;
      border: 1px solid $--form-color-base;
      background-color: $--form-color-base;
    }
  }
  .customer-service {
    display: flex;
    margin-top: 46px;
    width: 260px;
    height: 70px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 3px;
    .customer-service-img {
      width: 48px;
      padding-top: 16px;
      padding-left: 27px;
    }
    .customer-service-intro {
      padding-top: 14px;
    }
    .intro-title {
      font-size: 13px;
      color: #999999;
    }
    .intro-desc {
      margin-top: 7px;
      font-size: 13px;
      color: #333;
      span {
        display: inline-block;
        margin-right: 12px;
      }
    }
  }
}
.intro-dialog {
  ::v-deep {
    .el-dialog {
      border-radius: 4px;
    }
    .el-dialog__header {
      height: 59px;
      line-height: 59px;
      border-bottom: 1px solid #ececec;
      padding: 0 30px;
      text-align: center;
      .el-dialog__title {
        font-size: 16px;
        font-weight: 700;
        color: #434343;
        line-height: 59px;
      }
      .el-dialog__headerbtn {
        top: 16px;
        right: 18px;
        font-size: 22px;
        font-weight: bold;
        .el-dialog__close {
          color: #999999;
          &:hover {
            color: #444444;
          }
        }
      }
    }
    .el-dialog__body {
      padding: 10px 30px;
    }
  }
  .intro-main {
    padding-bottom: 20px;
  }
  .intro-main-time {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    margin: 10px 0;
  }
  .intro-main-p {
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    span{
      color: #72C5A0;
    }
  }
}
</style>

<style lang="scss">
#app > .layout.success-layout {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  .page-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>