<template>
  <div class="submit-success-container">
    <OrderSuccess parentSite="pcb" />
  </div>
</template>

<script>
import OrderSuccess from '@@/views/success/index'
export default {
  name: 'ordersSubmitSuccess',
  components: {
    OrderSuccess
  }
}
</script>

<style lang="scss" scoped>
</style>
